.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Scroll {
  height:600px;
  overflow:auto;
}
.Scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.Scroll::-webkit-scrollbar-track {
  background: #FFFBF6; 
}
 
/* Handle */
.Scroll::-webkit-scrollbar-thumb {
  background: #C4C4C4; 
  border-radius:4px
}

.swiper{
  height:600px;
  overflow:auto;
}
.swiper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.swiper::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
.swiper::-webkit-scrollbar-thumb {
  background: #C4C4C4; 
  border-radius:4px
}

.InputPlaceholder::placeholder{
  color:'white'
}
.parentclassname input[type='password']::-ms-reveal,
.parentclassname input[type='password']::-ms-clear {
display: none;
}

.monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
border-radius: 4px;
padding: 4px 0px 4px 0px;
}

/*invite toast style*/
.Toastsuccess{
position: absolute;
top:17px
}
.invitemsg{
margin-left:25px
}


#no-copy {
user-select: none;
}
#no-copy::selection {
background: none;
}
#no-copy::-moz-selection {
background: none;
}
.about_sty{
  color: #2F2F2F;
    font-size: 24px !important;
    font-style: normal;
    font-family: Rational;
    font-weight: 600 !important;
    line-height: 28px;
    letter-spacing: -0.02em;
}

table, th, td {
  border:1px solid black;
  border-collapse: collapse;
}

