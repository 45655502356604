body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.timer {
  font-family: 'Rational';
}
@font-face {
  font-family: "Rational";
  font-weight: 700;
  src: local('Rational'), url(./fonts/rational.otf) format('opentype');
}

/*CSS for transition screens*/
.containerForLoading {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.itemForLoading {
  width: 100px;
  height: 100px;
  position: absolute;
}

@media only screen and (max-width: 420px) {
  .itemForLoading {
    width: 75px;
    height: 75px;
  }
  .containerForLoading {
    width: 150px;
    height: 150px;
  }
}

.item-1-for-loading {
  background-color: #1789FC;
  top: 0;
  left: 0;
  z-index: 1;
  animation: item-1-for-loading_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-2-for-loading {
  background-color: #FF941C;
  top: 0;
  right: 0;
  animation: item-2-for-loading_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-3-for-loading {
  background-color: #D1A1EE;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: item-3-for-loading_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

.item-4-for-loading {
  background-color: #FAC06A;
  bottom: 0;
  left: 0;
  animation: item-4-for-loading_move 1.8s cubic-bezier(.6,.01,.4,1) infinite;
}

@media (min-width:420px)  {
  @keyframes item-1-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(0, 100px)} 
    50% {transform: translate(100px, 100px)} 
    75% {transform: translate(100px, 0)}
  }
  
  @keyframes item-2-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(-100px, 0)} 
    50% {transform: translate(-100px, 100px)} 
    75% {transform: translate(0, 100px)} 
  }
  
  @keyframes item-3-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(0, -100px)} 
    50% {transform: translate(-100px, -100px)} 
    75% {transform: translate(-100px, 0)} 
  }
  
  @keyframes item-4-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(100px, 0)} 
    50% {transform: translate(100px, -100px)} 
    75% {transform: translate(0, -100px)} 
  }
}

@media (max-width:420px)  {
  @keyframes item-1-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(0, 75px)} 
    50% {transform: translate(75px, 75px)} 
    75% {transform: translate(75px, 0)}
  }
  
  @keyframes item-2-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(-75px, 0)} 
    50% {transform: translate(-75px, 75px)} 
    75% {transform: translate(0, 75px)} 
  }
  
  @keyframes item-3-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(0, -75px)} 
    50% {transform: translate(-75px, -75px)}
    75% {transform: translate(-75px, 0)} 
  }
  
  @keyframes item-4-for-loading_move {
    0%, 100% {transform: translate(0, 0)} 
    25% {transform: translate(75px, 0)} 
    50% {transform: translate(75px, -75px)} 
    75% {transform: translate(0, -75px)} 
  }
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

/*CSS for rounded loading indicator*/


.roundedLoad{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width:100px;
  height:100px;
}
.roundedLoad hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}

.roundedLoad :first-child{background:#1789FC;animation-delay:-1.5s}
.roundedLoad :nth-child(2){background:#FF941C;animation-delay:-1s}
.roundedLoad :nth-child(3){background:#D1A1EE;animation-delay:-0.5s}
.roundedLoad :nth-child(4){background:#FAC06A}

@keyframes spin{
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
}

input:-internal-autofill-selected {
  background-color:#EBEFF8 !important
}
